<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <div class="c_group">
          <XzTagGroup
            style="border-bottom: none"
            :data="statusWithAll"
            :active="outform.status"
            @handleClick="v => handleOutChange('status', v)"
          >
            <span class="title">
              寄修状态
              <span class="c_help">
                <el-tooltip effect="dark" placement="top-start">
                  <div slot="content">
                    寄修状态说明：
                    <br />
                    待寄出：待用户将维修产品寄出
                    <br />
                    待签收：用户已寄出需维修产品，待我方签收
                    <br />
                    已签收待寄回：我方已签收需维修产品，需等待维修完成后寄回给用户
                    <br />
                    已寄回待签收：我方维修完成并已寄出产品，需等待用户签收；在用户签收之前可更新寄回信息
                    <br />
                    已寄回已签收：用户已经签收我方寄出的维修完成的产品
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </XzTagGroup>
          <XzTagGroup
            style="padding-top: 0"
            :data="validationStatusWithAll"
            :active="outform.checkStatus"
            @handleClick="v => handleOutChange('checkStatus', v)"
          >
            <span class="title">
              验收状态
              <span class="c_help">
                <el-tooltip effect="dark" placement="top-start">
                  <div slot="content">
                    验收状态说明：
                    <br />
                    未验收：用户未验收
                    <br />
                    验收合格：用户已验收，验收结果为合格
                    <br />
                    验收不合格：用户已验收，验收结果为不合格
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </XzTagGroup>
        </div>
        <div class="table-wrapper" :class="{ fullscreen: isFullScreen }">
          <xz-table
            ref="xzTable"
            :columns="columnLists"
            :data="data"
            :searchAttrs="searchAttrs"
            :searchVal.sync="form.code"
            :toolsAttrs="toolsAttrs"
            :scroll="{ x: '100%', y: scrollHeight }"
            :drawerVisible.sync="drawerVisible"
            :drawerWidth="500"
            rowKey="id"
            @search="handleAdvancedQuery"
            @refresh="handleReset"
            @fullScreen="handleFullScreen"
            @expand="expandedOneRow"
          >
            <template #drawer>
              <div style="flex: 1; overflow: auto" class="advanced-search">
                <el-form :model="form" label-width="100px">
                  <el-form-item label="寄修单号">
                    <el-input v-model="form.code" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="用户名称">
                    <el-input v-model="form.userParty" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="创建人">
                    <el-input v-model="form.createUserName" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="联系方式">
                    <el-input v-model="form.createUserPhone" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="物流公司">
                    <el-select v-model="form.courierName" placeholder="请选择" clearable filterable>
                      <el-option
                        v-for="opt in expressOptions"
                        :key="opt.optionCode"
                        :label="opt.optionName"
                        :value="opt.optionName"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="物流单号">
                    <el-input v-model="form.courierNumber" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="关联询单号">
                    <el-input v-model="form.inquirePriceCode" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="设备名称">
                    <el-input v-model="form.deviceName" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="设备品牌">
                    <el-input v-model="form.deviceBrandName" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="设备型号">
                    <el-input v-model="form.deviceModel" placeholder="请输入" clearable />
                  </el-form-item>
                  <el-form-item label="推送状态">
                    <el-select v-model="form.syncStatus" placeholder="请选择" clearable filterable>
                      <el-option label="未同步" :value="0"></el-option>
                      <el-option label="成功" :value="1"></el-option>
                      <el-option label="失败" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <div style="text-align: right; height: 68px; line-height: 68px">
                <a-button @click="handleEmpty">重置</a-button>
                <a-button type="primary" style="margin-left: 10px" @click="handleAdvancedQuery">确定</a-button>
              </div>
            </template>
            <template #custom>
              <el-popover
                placement="left"
                width="300"
                :tabindex="10000"
                popperClass="JZPopper"
                v-model="showAttribute"
                trigger="manual"
              >
                <div class="top">
                  <span>字段设置</span>
                  <time @click="showAttribute = false">&times;</time>
                </div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists && lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox
                        v-model="item.isShow"
                        :disabled="item.isDisabled"
                        style="margin-top: 6px"
                      ></el-checkbox>
                      <div class="labelContainer">{{ item.fieldName }}</div>
                      <i
                        :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                        style="font-size: 12px; margin-top: 10px; margin-left: 12px"
                        @click="handleClickLock(item, index)"
                      ></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px; margin-top: 10px; margin-left: 12px"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">锁定下列项目到此固定</li>
                  </template>
                </draggable>
                <h3
                  style="
                    border-top: 1px solid #dfe2e8;
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    margin-bottom: 0;
                  "
                >
                  不固定
                </h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox
                      v-model="item.isShow"
                      :disabled="item.isDisabled"
                      style="margin-top: 6px"
                    ></el-checkbox>
                    <div class="labelContainer">{{ item.fieldName }}</div>
                    <i
                      :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                      style="font-size: 12px; margin-top: 10px; margin-left: 12px"
                      @click="handleClickLock(item, index)"
                    ></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px; margin-top: 10px; margin-left: 12px"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button
                  slot="reference"
                  @click="handleSort"
                  style="width: 32px; height: 32px; margin-left: 8px; padding: 8px"
                >
                  <img :src="require('@/assets/ic_setting.svg')" style="width: 16px; height: 16px; font-size: 16px" />
                </el-button>
              </el-popover>
            </template>
            <span slot="syncStatus" slot-scope="_, record">
              <span>{{ syncStatusMsg(record.syncStatus) }}</span>
              <el-tooltip
                v-if="record.syncStatus === 2"
                effect="dark"
                :content="`失败原因：${record.syncFailReason}`"
                placement="top-start"
              >
                <i
                  :class="['el-icon-warning-outline', record.syncFailReason === 2 ? `status-${record.status}` : '']"
                ></i>
              </el-tooltip>
            </span>
            <span slot="statusMsg" slot-scope="_, record">
              <span v-if="record.statusMsg" :class="['c_tag', record.status ? `status-${record.status}` : '']">
                {{ record.statusMsg }}
              </span>
              <el-tooltip
                v-if="record.status === 8"
                effect="dark"
                :content="`关闭原因：${record.closeReason}`"
                placement="top-start"
              >
                <i :class="['el-icon-warning-outline', record.status ? `status-${record.status}` : '']"></i>
              </el-tooltip>
            </span>
            <span slot="code" slot-scope="text, record">
              <span :class="[sendRepairDetailPermission ? 'code' : '']" @click="() => handleView(record)">
                {{ text }}
              </span>
            </span>
            <div slot="action" slot-scope="_, record">
              <a v-if="sendRepairDetailPermission" @click="() => handleAction(record, 'view')">查看</a>
              <a
                v-if="sendRepairSendbackPermission && record.status === 3 && record.checkStatus === 0"
                style="margin-left: 8px"
                @click="() => handleAction(record, 'sendBack')"
              >
                寄回
              </a>
              <a
                v-if="sendRepairSignForPermission && record.status === 2 && record.checkStatus === 0"
                style="margin-left: 8px"
                @click="() => handleAction(record, 'signFor')"
              >
                签收
              </a>
              <a
                v-if="sendRepairPushPermission && record.status !== 8 && record.syncStatus !== 1"
                style="margin-left: 8px"
                @click="() => handleAction(record, 'push')"
              >
                推送
              </a>
            </div>
            <xz-table
              class="subTable"
              slot="expandedRowRender"
              slot-scope="record"
              :columns="subColumnsLists"
              :data="record.courierItemList"
            ></xz-table>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination
        :current="page.pageNum"
        :total="total"
        :show-total="total => `共 ${total} 条`"
        show-size-changer
        show-quick-jumper
        :pageSize.sync="page.pageSize"
        @change="handleNumChange"
        @showSizeChange="handleSizeChange"
      />
    </div>
    <DetailDialog
      ref="DetailDialogRef"
      v-if="dialogVisible"
      :dialog-visible.sync="dialogVisible"
      :id="currentRow.id"
    ></DetailDialog>
    <SignForDialog ref="signForDialogRef" @callback="handleCallback"></SignForDialog>
    <SendbackDialog ref="sendbackDialogRef" @callback="handleCallback"></SendbackDialog>
  </div>
</template>

<script>
import { sendRepairPageList, sendRepairStatusStatistics, pushToDockingSystem } from '@/api/logistics.js';
import { getFiledRuleLists, addFiledRule } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '@/components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import draggable from 'vuedraggable';
import DetailDialog from './components/detailDialog.vue';
import SignForDialog from '../components/signForDialog.vue';
import SendbackDialog from '../components/sendbackDialog.vue';
import { dictionaryData } from '@/api/inquiryForm.js';
import { Loading } from 'element-ui';
import mixin from '../mixin';

const COMMON_COLUMNS = [
  {
    title: '寄修单号',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    ellipsis: true,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '寄修状态',
    dataIndex: 'statusMsg',
    key: 'statusMsg',
    width: 140,
    ellipsis: true,
    scopedSlots: { customRender: 'statusMsg' },
  },
  {
    title: '验收状态',
    dataIndex: 'checkStatusMsg',
    key: 'checkStatusMsg',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '用户名称',
    dataIndex: 'userParty',
    key: 'userParty',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '创建人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '联系方式',
    dataIndex: 'createUserPhone',
    key: 'createUserPhone',
    width: 160,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '关联询单',
    dataIndex: 'inquirePriceCode',
    key: 'inquirePriceCode',
    width: 200,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    key: 'deviceName',
    width: 200,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '设备品牌',
    dataIndex: 'deviceBrandName',
    key: 'deviceBrandName',
    width: 200,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '设备型号',
    dataIndex: 'deviceModel',
    key: 'deviceModel',
    width: 200,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '推送状态',
    dataIndex: 'syncStatus',
    key: 'syncStatus',
    width: 200,
    ellipsis: true,
    scopedSlots: { customRender: 'syncStatus' },
  },
];

export default {
  name: 'LOGISTICS_SEND_FOR_REPAIR',
  mixins: [mixin],
  components: {
    xzTable,
    XzTagGroup,
    draggable,
    DetailDialog,
    SignForDialog,
    SendbackDialog,
  },
  computed: {
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
    statusWithAll () {
      return [
        {
          label: '全部',
          value: '',
        },
        {
          label: `待寄出(${this.counter?.waitSendOutCount || 0})`,
          value: 1,
        },
        {
          label: `待签收(${this.counter?.waitSendOutSignCount || 0})`,
          value: 2,
        },
        {
          label: `已签收待寄回(${this.counter?.waitSendBackCount || 0})`,
          value: 3,
        },
        {
          label: `已寄回待签收(${this.counter?.waitSendBackSignCount || 0})`,
          value: 4,
        },
        {
          label: `已寄回已签收(${this.counter?.sendBackSignedCount || 0})`,
          value: 5,
        },
        {
          label: `已关闭(${this.counter?.closedCount || 0})`,
          value: 8,
        },
      ];
    },
  },
  data () {
    return {
      validationStatusWithAll: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未验收',
          value: 0,
        },
        {
          label: '验收合格',
          value: 1,
        },
        {
          label: '验收不合格',
          value: 2,
        },
      ],
      columnLists: [
        ...COMMON_COLUMNS,
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 140,
          scopedSlots: { customRender: 'action' },
        },
      ],
      subColumnsLists: [
        {
          title: '收发状态',
          dataIndex: 'typeMsg',
          key: 'typeMsg',
          width: 100,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 100,
          ellipsis: true,
          customRender: text => {
            return text ? Moment(text).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          title: '物流信息',
          dataIndex: 'courierName',
          key: 'courierName',
          width: 100,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '物流单号',
          dataIndex: 'courierNumber',
          key: 'courierNumber',
          width: 100,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '物流状态',
          dataIndex: 'courierStatus',
          key: 'courierStatus',
          width: 100,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
      ],
      data: [],
      outform: {
        status: '',
        checkStatus: '',
      },
      form: {
        code: '',
        userParty: '',
        createUserName: '',
        createUserPhone: '',
        courierName: '',
        courierNumber: '',
        requirementCode: '',
        inquirePriceCode: '',
        deviceName: '',
        deviceBrandName: '',
        deviceModel: '',
        syncStatus: '',
      },
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入寄修单号',
        data: 'code',
      },
      drawerVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      scrollHeight: 600,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      lockLists: [],
      attrPropLists: [],
      lockPropLists: [],
      counter: {},
      dialogVisible: false,
      currentRow: {},
      expressOptions: [],
    };
  },
  methods: {
    syncStatusMsg (syncStatus) {
      switch (syncStatus) {
      case 0:
        return '未同步';
      case 1:
        return '成功';
      case 2:
        return '失败';
      default:
        return '--';
      }
    },
    handleView (record) {
      if (this.sendRepairDetailPermission) {
        this.currentRow = record;
        this.dialogVisible = true;
      }
    },
    handleEmpty () {
      this.form = this.$options.data().form;
    },
    handleReset () {
      this.outform = this.$options.data().outform;
      this.form = this.$options.data().form;
      this.page.pageNum = 1;
      this.page.pageSize = 10;

      this.handleSearch();
    },
    handleAdvancedQuery () {
      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleSizeChange (_, size) {
      this.page.pageSize = size;

      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
    async handleSearch () {
      this.data = [];
      const params = {
        type: '1', // 寄修单
        ...this.outform,
        ...this.form,
        ...this.page,
        findLogisticsTrackFlag: true,
        findRecordFlag: true,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };

      const { body } = await sendRepairPageList(params);

      this.data = body?.list || [];

      this.total = (body?.total || 0) * 1;

      if (this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    handleOutChange (key, o) {
      this.outform[key] = o.value;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleFullScreen (flag) {
      this.isFullScreen = flag;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let columnLists = COMMON_COLUMNS;
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType: 'logisticsOfSendRepair',
        isRegular: false,
        isShow: true,
        sort: index + 1,
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
    },
    handleSave () {
      let lists = [...this.lockLists, ...this.attrLists].map((i, index) => ({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        this.getRuleLists();
        this.showAttribute = false;
      });
    },
    // 这里需要处理table
    getColumnsLists (lists) {
      let showIdLists = lists.filter(i => i.isShow).map(i => i.fieldKey);
      let fixedIdLists = lists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);

      let currentLists = COMMON_COLUMNS.filter(i => showIdLists.includes(i.dataIndex)).map(i => ({
        ...i,
        fixed: fixedIdLists.includes(i.dataIndex) ? 'left' : '',
      }));
      let tempLists = [];
      for (let i = 0; i < showIdLists.length; i++) {
        const info = currentLists.find(j => j.key === showIdLists[i]);
        tempLists.push(info);
      }

      const columnLists = [
        ...tempLists,
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 140,
          scopedSlots: { customRender: 'action' },
        },
      ];

      this.$nextTick(() => {
        this.columnLists = columnLists;
      });
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      getFiledRuleLists({
        businessType: 'logisticsOfSendRepair',
      }).then(({ body }) => {
        let currentLists = body || [];

        if (currentLists && currentLists.length) {
          this.attrLists = currentLists.filter(i => !i.isRegular);
          this.lockLists = currentLists.filter(i => i.isRegular);
          this.getColumnsLists(currentLists);
        } else {
          let columnLists = [
            ...COMMON_COLUMNS,
            {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              fixed: 'right',
              width: 140,
              scopedSlots: { customRender: 'action' },
            },
          ];

          // 这里需要根据 columnLists 获取需要处理的数据的值
          let lockLists = columnLists
            .filter(i => i.fixed && i.title !== '操作')
            .map((i, index) => ({
              fieldKey: i.key,
              fieldName: i.title,
              businessType: 'logisticsOfSendRepair',
              isRegular: false,
              isShow: true,
              sort: index + 1,
            }));
          let attrLists = columnLists
            .filter(i => !i.fixed)
            .map((i, index) => ({
              fieldKey: i.key,
              fieldName: i.title,
              businessType: 'logisticsOfSendRepair',
              isRegular: false,
              isShow: true,
              sort: lockLists.length + index + 1,
            }));

          this.attrLists = attrLists;
          this.lockLists = lockLists;
        }

        // 这里需要拼接字段属性
        this.attrPropLists = this.attrLists.map(i => ({
          ...i,
          isDisabled: ['code', 'statusMsg', 'checkStatusMsg'].includes(i.fieldKey),
        }));
        this.lockPropLists = this.lockLists;
      });
    },
    async getCount () {
      const { body } = await sendRepairStatusStatistics({ type: 1 }); // 1 => 寄修

      this.counter = body;
    },
    handleAction (record, action) {
      if (action === 'sendBack') {
        this.$refs.sendbackDialogRef.expressOptions = this.expressOptions;
        this.$refs.sendbackDialogRef.init(record.id);
      } else if (action === 'signFor') {
        this.$refs.signForDialogRef.init(record.id);
      } else if (action === 'view') {
        this.$router.push({
          name: 'LOGISTICS_SEND_FOR_REPAIR_DETAIL',
          query: {
            id: record.id,
          },
        });
      } else if (action === 'push') {
        this.$confirm(`确认重新推送${record.code}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.handlePush(record);
        });
      }
    },
    async handlePush (record) {
      const { heads } = await pushToDockingSystem({
        id: record.id,
      });

      if (heads.code === 100002) {
        this.$message.error(heads.message);
      } else if (heads.code === 200) {
        this.$message.success('推送成功');
      }

      if ([100002, 200].includes(heads.code)) {
        const loading = Loading.service({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          zIndex: 99999,
        });

        setTimeout(() => {
          loading.close();
          this.handleSearch();
        }, 1500);
      }
    },
    handleCallback () {
      this.getCount();
      this.handleSearch();
    },
    expandedOneRow (_, rec) {
      this.$nextTick(() => {
        setTimeout(() => {
          const subtbs = document.querySelectorAll('.subTable');

          if (subtbs.length === 2) {
            const fixedLeftEle = document.querySelector('.ant-table-fixed-left').querySelector('.ant-table-body-inner');

            if (fixedLeftEle) {
              fixedLeftEle.style.overflow = 'hidden';
            }

            subtbs[1].style.width = subtbs[0].offsetWidth + 'px';
            subtbs[0].style.marginLeft = -52 + 'px';
          }

          const elements = document.querySelectorAll(`[data-row-key='${rec.id}-extra-row']`);
          const length = elements.length;
          const height = elements[0].offsetHeight - 19;

          elements[length - 1].childNodes[0].innerHTML = `<div style="height: ${height}px;"></div>`;
        }, 0);
      });
    },
    async getExpressDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'EXPRESS_COMPANY',
      });

      this.expressOptions = body;
    },
  },
  created () {
    this.getRuleLists();
    this.getExpressDictionaryData();
  },
  mounted () {
    this.getCount();
    this.handleSearch();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 16px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .c_group {
        position: relative;

        .title {
          font-size: 14px;
          font-weight: 600;
          padding-right: 2em;
        }
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #f2f4f7;

          &.active-radio {
            border-color: #e2f3fe;
            background-color: #e2f3fe;
            color: #237ffa;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .c_tag {
          @include tag_round();
          margin-right: 0.2em;
        }

        .status-2,
        .status-3 {
          background-color: #fefae0;
          color: #fab007;

          &:before {
            color: #fab007;
          }
        }

        .status-4,
        .status-1 {
          background: #e2f3fe;
          color: #237ffa;

          &:before {
            color: #237ffa;
          }
        }

        .status-5 {
          background: #E5FEEB;
          color: #14CA64;

          &:before {
            color: #14CA64;
          }
        }

        .status-8 {
          background-color: #f2f4f7;
          color: #1f2733;

          &:before {
            color: #1f2733;
          }
        }

        .code {
          color: #237ffa;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 100;
  background: #ffffff;
}

.text-right {
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}

.top {
  display: flex;
  padding: 6px 0;
  background: #ffffff;
  box-shadow: inset 0 -1px 0 0 #dfe2e8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1f2733 100%;
  letter-spacing: 0px;
  opacity: 1;

  span {
    flex: 1;
    height: 24px;
    line-height: 24px;
  }

  time {
    cursor: pointer;
  }
}

.list-item {
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;

  .el-checkbox__label {
    height: 32px;
    line-height: 32px;
  }

  .labelContainer {
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}

.noFixedItem {
  font-family: PingFang SC;
  font-size: 14px;
  color: #929aa6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #ffffff;
}

/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #c9ced6;
  background: #c9ced6;
}

//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #c9ced6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

/deep/.ant-drawer-body {
  padding: 16px;
}
</style>
